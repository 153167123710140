export const MEANDER_DASHBOARD_MENU_LIST = [
  {
    linkTitle: 'Home',
    link: 'home',
    id: 'home',
    icon: 'home',
    selected: true
  },
  {
    linkTitle: 'Users',
    link: 'users',
    id: 'passengers',
    icon: 'people',
    selected: false,
  },
  {
    linkTitle: 'Organizations',
    link: 'organizations',
    id: 'teams',
    icon: 'business',
    selected: false,
  },
  {
    linkTitle: 'Airlines',
    link: 'airlines',
    id: 'airlines',
    icon: 'local_airport',
    selected: false,
  },
  {
    linkTitle: 'Stamps',
    link: 'stamps',
    id: 'stamps',
    icon: 'stamps.svg',
    selected: false,
  },
  {
    linkTitle: 'Trip Reviews',
    link: 'flight-reviews',
    id: 'ratings',
    icon: 'star_border',
    selected: false,
  },
  {
    linkTitle: 'Surveys',
    link: 'survey',
    id: 'survey',
    icon: 'checklist',
    selected: false,
  },
  {
    linkTitle: 'FTUE Questions',
    link: 'ftue-questions',
    id: 'ftueQuestions',
    icon: 'question_answer',
    selected: false,
  },
  {
    linkTitle: 'Question Bank',
    link: 'question-bank',
    id: 'questionBank',
    icon: 'storage',
    selected: false,
  },
  {
    linkTitle: 'Rewards',
    link: 'rewards',
    id: 'rewards',
    icon: 'card_giftcard',
    selected: false,
  },
/*  {
    linkTitle: 'Activities',
    link: 'activities',
    id: 'activities',
    icon: 'settings_input_composite',
    selected: false,
  },*/
  /*  {
      linkTitle: 'Logs',
      link: 'logs',
      id: 'logs',

      icon: 'cloud_circle',
      selected: false,
    },*/
];
export const PARTNER_DASHBOARD_MENU_LIST = [{
  linkTitle: 'Surveys',
  link: 'survey',
  id: 'survey',
  icon: 'checklist',
  selected: false,
},
  {
    linkTitle: 'Organization',
    link: 'organizations',
    id: 'teams',
    icon: 'business',
    selected: false,
  },
  {
    linkTitle: 'Billing',
    link: 'billing',
    id: 'Billing',
    icon: 'payment',
    selected: false,
  },
  {
    linkTitle: 'Rewards',
    link: 'rewards',
    id: 'rewards',
    icon: 'card_giftcard',
    selected: false,
  },
  {
    linkTitle: 'Integrations',
    link: 'integrations',
    id: 'integrations',
    icon: 'settings_input_composite',
    selected: false,
  },
/*  {
    linkTitle: 'Activities',
    link: 'activities',
    id: 'activities',
    icon: 'settings_input_composite',
    selected: false,
  },*/
  ];

export const PULSE_USER_ROLES = {
  PULSE_ROLES: {
    PULSE_ADMIN: 1,
    PULSE_EDITOR: 4,
  },
  PARTNER_ROLES: {
    PARTNER_OWNER: 5,
    PARTNER_ADMIN: 2,
    PARTNER_EDITOR: 3
  }
};

export const ORGANIZATION_INVITE_STATUS = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
};

export const USER_ROLES_TEXT = {
  ADMIN: 'Admin',
  MEMBER: 'Member',
  EDITOR: 'Editor',
};

export const SURVEY_AVAILABLE_ENVIRONMENTS = {
  TEST: 'test',
  LIVE: 'live'
};

export const LOGS_LIST = {
  CURRENCY_CONVERT: 'currency_convert',
  DUFFEL_CONFIRM_CANCEL_ORDER: 'duffel_confirm_cancel_order',
  DUFFEL_CREATE_CANCEL_ORDER: 'duffel_create_cancel_order',
  DUFFEL_GET_REFUND_STATUS: 'duffel_get_refund_status',
  DUFFEL_CREATE_PAYMENT_INTENT: 'duffel_create_payment_intent',
  DUFFEL_SEAT_MAPS: 'duffel_seat_maps',
  CREATE_DUFFEL_ORDER: 'create_duffel_order',
  SAVE_AVAILABILITY_UPDATES: 'save_availability_updates',
  SEAT_SELECTION: 'seat_selection',
  CALENDAR_PRICE: 'calendar_price',
  SELECTED_FLIGHT_DETAILS: 'selected_flight_details',
  USER_FTUE: 'user_ftue',
  KIWI_REFUND_REQUEST: 'kiwi_refund_request',
  KIWI_SEAT_MAP: 'kiwi_seat_map',
  TOP_DESTINATIONS: 'top_destinations',
  PRIVATE_SEARCH: 'private_search',
  REWARD_REDEEM: 'reward_redeem',
  GET_REWARD_LIST: 'get_reward_list',
  SELECTED_REWARD_DETAILS: 'selected_reward_details',
  VISA_REQUIREMENTS: 'visa_requirements',
  MAKE_VC_STRIPE_PAYMENT: 'make_vc_stripe_payment',
  MOBILE_SEARCH: 'mobile_search',
  SUBMIT_SURVEY: 'submit_survey',
  SUBMIT_FLIGHT_REVIEW: 'submit_flight_review',
  PARTNER_CREATED_SURVEY: 'partner_created_survey',
  PARTNER_GET_SURVEYS_LIST: 'partner_get_surveys_list',
  PARTNER_GET_SURVEY_BY_ID: 'partner_get_survey_by_id',
  PARTNER_UPDATE_SURVEY: 'partner_update_survey',
  GET_SURVEYS_FOR_USERS: 'get_surveys_for users',
  SEND_OTP: 'send_otp',
  VERIFY_OTP: 'verify_otp',
  DUFFEL_WEBHOOK: 'duffel_webhook',
  KIWI_WEBHOOK: 'kiwi_webhook',
  SHARE_BOOKING_VIA_EMAIL: 'share_booking_via_email',
  PREPARE_EMAIL_TEMPLATE: 'prepare_email_template',
  SIGN_UP: 'sign_up',
  LOG_IN: 'log_in',
  UPDATE_USER_PROFILE: 'update_user_profile',
  DELETE_USER_PROFILE: 'delete_user_profile',
  SAVE_BOOKING: 'save_booking',
};

export const REWARDS_COUNTRIES = [
  {
    is_custom: true,
    country_name: null,
    country_code: null,
    rewards: null
  },
  {
    country_name: 'United States',
    country_code: 'US',
    rewards: null
  },
  {
    country_name: 'Canada',
    country_code: 'CA',
    rewards: null
  },
  {
    country_name: 'India',
    country_code: 'IN',
    rewards: null
  }, {
    country_name: 'Ireland',
    country_code: 'IE',
    rewards: null
  }, {
    country_name: 'United Kingdom',
    country_code: 'GB',
    rewards: null
  }, {
    country_name: 'Netherlands',
    country_code: 'NL',
    rewards: null
  }, {
    country_name: 'Germany',
    country_code: 'DE',
    rewards: null
  }, {
    country_name: 'Australia',
    country_code: 'AU',
    rewards: null
  }
];

export const COLLECTIONS = {
  ORGANIZATION_BILLING_DETAILS: 'organizationBillingDetails',
  ORGANIZATIONS: 'organizations',
  USERS: 'users',
  SURVEYS: 'surveys',
  JOURNEY_SURVEYS: 'journeySurveys',
};


export const REWARD_TYPES = {
  GIFTBIT: {
    id: 1,
    TEXT: 'GIFTBIT'
  },
  TREMENDOUS: {
    id: 2,
    TEXT: 'TREMENDOUS'
  },
  CUSTOM: {
    id: 3,
    TEXT: 'CUSTOM'
  },
};

export const SUBSCRIPTION_STATUS = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
  PAUSED: 'paused'
};

export const PLAN_TYPES = {
  STARTER: {
    ID: 1,
    LABEL: 'STARTER',
  },
  GROWTH: {
    ID: 2,
    LABEL: 'GROWTH',
  },
  ENTERPRISE: {
    ID: 3,
    LABEL: 'ENTERPRISE',
  }
};
